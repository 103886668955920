<template>

  <div class="bet_cart_section">
    <div class="bet_cart">
      <div class="info_bet">
        <div class="info_box">
          <div  class="game01">
            <h4>{{i18nConst.COMMON['SELECTED'][$store.state.country]}} <span>{{selectedInfo.selectText}}</span></h4>
          </div>
          <div class="game01">
            <h4 style="width: 100%;">
              {{i18nConst.BET['GAME_MONEY'][$store.state.country]}} <span>{{$store.state.userInfo.cash|comma}} $</span>
            </h4>
          </div>
          <div class="dividend">{{i18nConst.BET['RATE'][$store.state.country]}} <span>{{selectedInfo.seletedOdd}}</span></div>
          <div class="div_pay">{{i18nConst.BET['GAME_MONEY'][$store.state.country]}}<span>{{getWinCash|comma}} $</span> </div>
        </div>
        <div class="btn_box">
          <div>
            <button type="button" class="btn btn_minigame_addcash" @click="cashAdd(5)">5$</button>
          </div>
          <div>
            <button type="button" class="btn btn_minigame_addcash" @click="cashAdd(10)">10$</button>
          </div>
          <div>
            <button type="button" class="btn btn_minigame_addcash" @click="cashAdd(50)">50$</button>
          </div>
          <div>
            <button type="button" class="btn btn_minigame_addcash" @click="cashAdd(100)">100$</button>
          </div>
          <div>
            <button type="button" class="btn btn_minigame_addcash" @click="cashAdd(500)">500$</button>
          </div>

          <div>
            <button type="button" class="btn btn_minigame_addcash" @click="initBetcash">{{i18nConst.BET['INIT'][$store.state.country]}}</button>
          </div>
          <div>
            <button type="button" class="btn btn_minigame_addcash" @click="allInCash()">{{i18nConst.BET['ALL_IN'][$store.state.country]}}</button>
          </div>
        </div>
      </div>
      <div class="bet_moeny"> <span>{{i18nConst.BET['BET_CASH'][$store.state.country]}}</span> <input  type="text" v-model="betCash"></div>
    </div>
    <div class="btn_minigame">
      <button type="button" class="btn btn01" @click="doBet">{{i18nConst.BET['DO_BET'][$store.state.country]}}</button>
    </div>
  </div>


</template>

<script>
  import {doLeisureBet} from "../../network/leisureRequest";
  import {RECEIVE_SPORTS_CART_ITEM_DEL_ALL} from "../../store/mutation-types";
  import {checkLoginMinxin} from "../../common/mixin";
  import i18nConst from "../../common/i18nConst";

  export default {
    name: "LeisureBetCartComp",
    mixins:[checkLoginMinxin],
    props: {
      kind: {
        type: Number,
        default() {
          return 0
        }
      },
      selectedInfo: {
        selectedAttr: 0,
        selectedGameId: 0,
        selectedWay: 0,
        seletedOdd: 0,
        selectedText: '',
        config: {maxBetCash: 0, minBetCash: 1000, maxWinCash: 0}
      }
    },
    data() {
      return {
        i18nConst:i18nConst,
        isFirst: true,
        winCash: 0,
        betCash: 0,
        betInfo: {attribute: 0, way: 0, gameId: 0, kind: 0, betCash: 0}
      }
    },
    computed: {
      getWinCash() {
        this.winCash =  Math.round(this.getCleanBetCash * this.selectedInfo.seletedOdd)
        return this.winCash
      },
      getCleanBetCash() {
        return parseInt(this.$root.$options.filters.replace(this.betCash, ',', ''))
      },
    },
    methods: {
      /*버튼 올인*/
      allInCash() {
        this.betCash = this.$root.$options.filters.comma(this.$store.state.userInfo.cash)
      },
      /*버튼 초기화*/
      initBetcash() {
        if (this.isFirst) {
          this.betCash = this.$root.$options.filters.comma(this.selectedInfo.config.minBetCash)
          this.isFirst = false;
        } else {
          this.betCash = 0
        }
      },
      /*버튼 캐쉬클릭 ++*/
      cashAdd(amount) {
        if (this.betCash === '') {
          this.betCash = 0;
        }
        this.betCash = this.$root.$options.filters.comma(this.getCleanBetCash + amount);
      },
      /*베팅하기*/
      doBet() {
        if(!this.checkLogin()){
          return false
        }
        this.$swal({
          title: this.$root.$options.filters.replace( i18nConst.BET['DO_BET_MSG_1'][this.$store.state.country],'##',this.$root.$options.filters.comma(this.betCash)),
          type: 'question',
          confirmButtonText: i18nConst.COMMON['YES'][this.$store.state.country],
          cancelButtonText: i18nConst.COMMON['NO'][this.$store.state.country]
        }).then((res) => {
          this.betInfo = {selectedVals: []}
          if (res.value) {
            if (this.selectedInfo.selectedGameId === 0
              || this.selectedInfo.selectedAttr === 0
              || this.selectedInfo.selectedWay === 0
              || this.selectedInfo.seletedOdd === 0) {
              this.$swal({
                title: i18nConst.BET['SELECT_BET_GAME'][this.$store.state.country],
                type: 'error',
                showCancelButton: false,
                showConfirmButton: true
              })
              return false
            }


            //{attribute: 20, way: 2, gameId: 481007, kind: 2, betCash: 6000}
            this.betInfo.kind = this.kind
            this.betInfo.attribute = this.selectedInfo.selectedAttr
            this.betInfo.way = this.selectedInfo.selectedWay
            this.betInfo.gameId = this.selectedInfo.selectedGameId
            this.betInfo.betCash = this.getCleanBetCash
            doLeisureBet(this.betInfo).then(res => {
              if (res.data.success) {
                this.$swal({
                  title: i18nConst.BET['BET_SUCCESS'][this.$store.state.country],
                  type: 'success',
                  showCancelButton: false,
                  showConfirmButton: true
                })
                this.betCash = 0
                //베팅성공시 회$정보를 init
                //UserInfoComp.vue에서 모니터
                this.$store.dispatch('actionUserInfo')
                //선택초기화
                this.$bus.$emit('leisureBetSuccess')
              } else {
                this.$swal({
                  title: res.data.msg,
                  type: 'error',
                  showCancelButton: false,
                  showConfirmButton: true
                })
                setTimeout(() => {
                  this.$router.go(0)
                }, 1500)
              }
            })
          }
        })
      },
    },
    created() {

    },
    watch: {
      "selectedInfo": {
        deep: true,
        handler: function (newValue) {
          //console.log(newValue)
        }
      },
    }
  }
</script>

<style scoped>

</style>