<template>
  <div id="contents">
    <div class="mini_game_bet">
      <sub-title>파워볼 로또</sub-title>
      <div class="game_box">
        <leisure-game-view>
          <div class="view">
            <iframe id="gamefram" scrolling="no" frameborder="0"
                    src="http://named.com/games/powerball/view.php"></iframe>
          </div>
        </leisure-game-view>

        <div id="gamelist" class="bet_section">
          <leisure-bet-masker :show-masker="showMasker"></leisure-bet-masker>
          <leisure-timer-comp
              ref="ltimer"
              :leisure-game-info="leisureGameInfo"
              @timerStop="timerStop" @refreshTimer="refreshTimer"></leisure-timer-comp>

          <div class="bet_cart_box">
            <div class="bet_list01">

              <!--파워볼-->
              <div class="list_box01">
                <h4 style="color: #089fd4">파워볼</h4>
                <div class="bet_btn03">
                  <div class="btn01">
                    <button type="button" class="btn_blue pb"
                            :class="{'on':leisureGameBetInfo.powerball === 0}"
                            @click="selectPowerball(0)">0
                    </button>
                  </div>
                  <div class="btn01">
                    <button type="button" class="btn_blue pb"
                            :class="{'on':leisureGameBetInfo.powerball === 1}"
                            @click="selectPowerball(1)">1
                    </button>
                  </div>
                  <div class="btn01">
                    <button type="button" class="btn_blue pb"
                            :class="{'on':leisureGameBetInfo.powerball === 2}"
                            @click="selectPowerball(2)">2
                    </button>
                  </div>
                  <div class="btn01">
                    <button type="button" class="btn_blue pb"
                            :class="{'on':leisureGameBetInfo.powerball ===3}"
                            @click="selectPowerball(3)">3
                    </button>
                  </div>
                  <div class="btn01">
                    <button type="button" class="btn_blue pb"
                            :class="{'on':leisureGameBetInfo.powerball ===4}"
                            @click="selectPowerball(4)">4
                    </button>
                  </div>
                  <div class="btn01">
                    <button type="button" class="btn_blue pb"
                            :class="{'on':leisureGameBetInfo.powerball ===5}"
                            @click="selectPowerball(5)">5
                    </button>
                  </div>
                  <div class="btn01">
                    <button type="button" class="btn_blue pb"
                            :class="{'on':leisureGameBetInfo.powerball ===6}"
                            @click="selectPowerball(6)">6
                    </button>
                  </div>
                  <div class="btn01">
                    <button type="button" class="btn_blue pb"
                            :class="{'on':leisureGameBetInfo.powerball ===7}"
                            @click="selectPowerball(7)">7
                    </button>
                  </div>
                  <div class="btn01">
                    <button type="button" class="btn_blue pb"
                            :class="{'on':leisureGameBetInfo.powerball ===8}"
                            @click="selectPowerball(8)">8
                    </button>
                  </div>
                  <div class="btn01">
                    <button type="button" class="btn_blue pb"
                            :class="{'on':leisureGameBetInfo.powerball ===9}"
                            @click="selectPowerball(9)">9
                    </button>
                  </div>
                </div>
              </div>

              <!--파워볼-->
              <div class="list_box01">
                <h4 style="color: #089fd4">일반볼</h4>
                <div class="bet_btn04">
                  <div class="btn01" v-for="item in 28">
                    <button type="button" class="btn_red nb"
                            :class="{'on':leisureGameBetInfo.balls.findIndex((ball)=>{return ball === item}) !== -1}"
                            @click="selectRedball(item)"> {{item}}
                    </button>
                  </div>
                </div>
              </div>

            </div>
            <div class="bet_cart">
              <div class="info_bet">
                <div class="info_box">
                  <div class="game01">
                    <h4>게임선택
                      <span style="right: -20px">
                        <i v-for="(item,idx) in leisureGameBetInfo.balls" :key="idx" style="color: red">{{item}}, </i>
                        <i v-if="leisureGameBetInfo.powerball!=null" style="color: #9898f9">{{leisureGameBetInfo.powerball}}</i>
                      </span>
                    </h4>
                  </div>
                  <div class="game01">
                    <h4 style="width:100%;">보유금액 <span>{{$store.state.userInfo.cash|comma}}$</span></h4>
                  </div>
                  <div class="game01">
                    <h4 style="width:100%;">배팅금액 <span>{{getBetCash|comma}}$</span></h4>
                  </div>
                </div>
                <div class="btn_box">
                  <div>
                    <button type="button" @click="sell()">매도</button>
                  </div>
                  <div style="text-align: center;">
                    {{leisureGameBetInfo.betOdds}}
                  </div>
                  <div>
                    <button type="button" @click="buy(5000)">매수</button>
                  </div>
                  <div>
                    <button type="button" @click="autoSelect(10000)">자동선택</button>
                  </div>
                  <div>
                    <button type="button" @click="initBalls(100000)">초기화</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn_minigame">
              <button type="button" @click="doLottoBet">배팅하기</button>
            </div>
          </div>
        </div>
      </div>
      <!--최근 배팅내역-->
      <leisure-lately-bet-list-comp
          ref="latelyBetList"
          :kind="kind"></leisure-lately-bet-list-comp>
    </div> <!--./mini_game_bet end--->
  </div>
</template>

<script>
  import SubTitle from "../../../components/SubTitle";
  import LeisureTimerComp from "../../../components/leisuregame/LeisureTimerComp";
  import LeisureBetCartComp from "../../../components/leisuregame/LeisureBetCartComp";
  import leisureConst from "../../../common/leisureConst";
  import {doBetLotto, doLeisureBet, getOnBetLeisureGames} from "../../../network/leisureRequest";
  import LeisureBetMasker from "../../../components/leisuregame/LeisureBetMasker";
  import LeisureLatelyBetListComp from "../../../components/leisuregame/LeisureLatelyBetListComp";
  import LeisureGameView from "../../../components/leisuregame/LeisureGameView";

  export default {

    name: "Powerball",
    components: {
      LeisureGameView,
      LeisureLatelyBetListComp, LeisureBetMasker, LeisureBetCartComp, LeisureTimerComp, SubTitle
    },
    data() {
      return {
        kind: leisureConst.LEISURE_KIND_POWERBALL,
        showMasker: true,
        leisureGameInfo: {},
        leisureGameBetInfo: {balls: [], powerball: null, betOdds: 1, gameId: 0},
        betCash: 1000
      }
    },
    computed: {
      getBetCash() {
        return this.betCash * this.leisureGameBetInfo.betOdds
      }
    },
    methods: {
      //{gameId: 489057, betOdds: 1, powerball: 3, balls: [8, 25, 28, 27, 10]}
      selectPowerball(powerBall) {
        if (this.leisureGameBetInfo.powerball === powerBall) {
          this.leisureGameBetInfo.powerball = null
        } else {
          this.leisureGameBetInfo.powerball = powerBall
        }
      },
      selectRedball(redBall) {
        let index = this.leisureGameBetInfo.balls.findIndex((ball) => {
          return ball === redBall
        });
        //이미존재는 볼이면 삭제
        if (index !== -1) {
          this.leisureGameBetInfo.balls.splice(index, 1)
        } else {
          //일반볼 선택 개수 체크
          if (this.leisureGameBetInfo.balls.length >= 5) {
            this.$swal({
              title: '일반볼은 최대 5개만 선택가능합니다',
              type: 'error',
              showCancelButton: false,
              showConfirmButton: true
            })
            return false;
          }
          this.leisureGameBetInfo.balls.push(redBall)
        }

      },
      doLottoBet() {
        this.$swal({
          title: this.betCash + '을 베팅하시겠습니까?',
          type: 'question',
          confirmButtonText: ' 예 ',
          cancelButtonText: '아니오'
        }).then((res) => {
          this.betInfo = {selectedVals: []}
          if (res.value) {
            if (this.leisureGameBetInfo.balls.length < 5
              || this.leisureGameBetInfo.powerball == null) {
              this.$swal({
                title: '베팅하실 경기를 선택하세요',
                type: 'error',
                showCancelButton: false,
                showConfirmButton: true
              })
              return false
            }

            doBetLotto(this.leisureGameBetInfo).then(res => {
              if (res.data.success) {
                this.$swal({
                  title: '베팅성공',
                  type: 'success',
                  showCancelButton: false,
                  showConfirmButton: true
                })
                this.betCash = 1000
                //베팅성공시 회원정보를 init
                //UserInfoComp.vue에서 모니터
                this.$bus.$emit('betSuccess')
                this.$bus.$emit('leisureBetSuccess')
                //선택초기화
                this.initBalls()
              } else {
                this.$swal({
                  title: res.data.msg,
                  type: 'error',
                  showCancelButton: false,
                  showConfirmButton: true
                })
                setTimeout(() => {
                  this.$router.go(0)
                }, 1500)
              }
            })
          }
        })


      },
      sell() {
        if (this.leisureGameBetInfo.betOdds === 1) {
          return false;
        }
        this.leisureGameBetInfo.betOdds--;
      },
      buy() {
        if (this.leisureGameBetInfo.betOdds > 999) {
          return false;
        }
        this.leisureGameBetInfo.betOdds++;
      },
      autoSelect() {
        this.initBalls();
        //파워볼 설정
        this.leisureGameBetInfo.powerball = this.randomBlueNumber();
        //일반볼 설정
        let redball = this.randomRedNumber();
        redball.forEach((b) => {
          this.leisureGameBetInfo.balls.push(b)
        })
      },
      randomBlueNumber() {
        return Math.floor((Math.random() * 9) + 1);
      },
      randomRedNumber() {
        let redArray = [];
        while (true) {
          let redNum = Math.floor((Math.random() * 28) + 1);
          if (redNum === 0 || redNum > 28) {
            continue;
          }
          if (redArray.indexOf(redNum) === -1) {
            redArray.push(redNum);
          }
          if (redArray.length === 5) {
            break;
          }
        }
        return redArray;
      },
      initBalls() {
        this.leisureGameBetInfo.betOdds = 1
        this.leisureGameBetInfo.balls = []
        this.leisureGameBetInfo.powerball = null
        this.leisureGameBetInfo.gameId = this.leisureGameInfo.leisureGame.id
      },
      initLeisureGame() {
        getOnBetLeisureGames(this.kind, this.$moment().millisecond()).then(res => {

          if (res.data.success) {
            this.$set(this.leisureGameInfo, 'betStopLimitSecond', res.data.data.betStopLimitSecond)
            this.$set(this.leisureGameInfo, 'leisureGame', res.data.data.leisureGame)
            this.$set(this.leisureGameInfo, 'leisureSportsGames', res.data.data.leisureSportsGames)
            this.$set(this.leisureGameInfo, 'now', res.data.data.now)
            this.leisureGameBetInfo.gameId = this.leisureGameInfo.leisureGame.id
            this.$refs.ltimer.startTimeCount();
            this.showMasker = false

          } else {
            this.showMasker = true
            this.$refs.ltimer.setSequenceText(res.data.msg)
            setTimeout(() => {
              this.initLeisureGame()
            }, 5000)
          }
        })
      },
      refreshTimer() {
        this.initLeisureGame()
      },
      timerStop() {
        this.showMasker = true
        this.initLeisureGame();
      },
    }
    ,
    created() {
      this.initKindConfig(this.kind)
    },
  }
</script>

<style scoped>
  .pb {
    width: 50px !important;
    height: 50px !important;
    background-color: blue !important;
    border: 0 !important;
    color: #ffffff !important;
    padding-bottom: 0 !important;
    border-radius: 50% !important;
  }

  .nb {
    width: 50px !important;
    height: 50px !important;
    background-color: red !important;
    border: 0 !important;
    color: #ffffff !important;
    padding-bottom: 0 !important;
    border-radius: 50% !important;
  }

  .pb.on {
    border: 1px solid #089fd4;
    background: #f1f42f !important;
    color: #000000 !important;
  }

  .nb.on {
    border: 1px solid #089fd4;
    background: #f1f42f !important;
    color: #000000 !important;
  }
</style>